const Footer = () => {
    return (
        <footer className="p-16 mt-8" style={{backgroundColor: "rgb(45, 45, 43)"}}>
            <div className="container mx-auto">
                <div className="text-center">
                    <img src="/icons.png" alt="Danish design produced in Europe" className="mx-auto" />
                    <p className="text-white my-12">
                        Copyright &copy; All rights reserved<br />
                        Danish design produced in Europe
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
import { useEffect } from "react"
import { Routes, Route } from "react-router-dom"
import { useTranslation } from "react-i18next"
import Form from "./Form"
import Receipt from "./Receipt"
import Verified from "./Verified"
import Header from "./components/Header"
import Footer from "./components/Footer"

function App() {

  const { t, i18n } = useTranslation()

  useEffect(() => {
    document.documentElement.lang = i18n.language
    document.title = t("meta.title")
  }, [i18n.language,t])

  return (
    <div className="App">
      <Header />
      <div className="container mx-auto">
      <Routes>
        <Route path="/" element={<Form />} />
        <Route path="/receipt" element={<Receipt />} />
        <Route path="/:guid/:email" element={<Verified />} />
      </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;

import { useTranslation } from 'react-i18next';

const Language = () => {
    const { i18n } = useTranslation();

    return (
        <div>
            <select value={i18n.language} onChange={(e) => i18n.changeLanguage(e.target.value)} className="p-2 lg:mt-2 w-44 rounded-md">
                <option value="en">English</option>
                <option value="de">Deutsch</option>
                <option value="da">Dansk</option>
            </select>
        </div>
    )
}

export default Language;
import { useStoryblok } from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";
import { useTranslation } from "react-i18next";
import "./Storyblok.css";

const Storyblok = () => {

    const { i18n } = useTranslation();
    
    let slug = "cleverconveyor/" + i18n.language;
    const story = useStoryblok(slug, { version: "published" });
    
    if (!story || !story.content) {
        return (<div className="animate-pulse text-slate-500">Loading</div>);
    }

    return (
        <>
            {render(story.content.text)}
        </>
    )
}

export default Storyblok;
import { useTranslation } from "react-i18next";

export default function Receipt() {
    
    const { t } = useTranslation();

    return (
        <div className="text-center my-32">
            <h1 className="text-4xl lg:text-5xl text-center font-bold my-24 px-8 lg:px-0 text-brand-orange">{t("receipt.heading")}.</h1>
            <p className="text-lg">{t("receipt.text")}</p>
        </div>
    )
    
}
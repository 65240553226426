import React, { useState } from 'react'
import axios from 'axios'
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom";
import Storyblok from './components/Storyblok'

const Form = () => {

    const { t, i18n } = useTranslation()
    const navigate = useNavigate();

    const [serial,setSerial] = useState()
    const [company,setCompany] = useState()
    const [vat,setVat] = useState()
    const [industry,setIndustry] = useState()
    const [email,setEmail] = useState()
    const [phone,setPhone] = useState()
    const [name,setName] = useState()
    const [address,setAddress] = useState()
    const [address2,setAddress2] = useState()
    const [postno,setPostno] = useState()
    const [city,setCity] = useState()
    const [country,setCountry] = useState()
    const language = i18n.language;

    function handleSubmit(event) {
        event.preventDefault()
        axios.post('https://prod-177.westeurope.logic.azure.com:443/workflows/b0f02645e1264fb288ec44b274d50f1c/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=RGBGR5MLEyYONJPs051ZpCcmYB0QfRbOUOnjnDsgfpk', {
            "serial": serial,
            "lang": language,
            "industry": industry,
            "date": "",
            "company": company,
            "vatno": vat,
            "name": name,
            "address": address,
            "address2": address2,
            "postcode": postno,
            "city": city,
            "country": country,
            "email": email,
            "phone": phone
        }) 
        .then(function(response) {
            console.log(response)
            navigate("/receipt")
        })
    }

    return (
        <div>
            <h1 className="text-4xl lg:text-5xl text-center font-bold my-24 px-8 lg:px-0 text-brand-orange">{ t('form.title') }.</h1>
            <div className="grid lg:grid-cols-2 gap-16">
                <div className="mx-8 lg:mx-0">
                    <Storyblok />
                </div>
                <div>
                    <div className="bg-brand-sand p-8 lg:p-16 mx-8 lg:mx-0 rounded-lg">
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-xl mb-8 font-bold text-center">{t("form.formtitle")}</h2>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="serial" type="text" id="serial" placeholder={t("form.serial")} onChange={event => setSerial(event.target.value)} required />
                        </div>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="company" type="text" id="company" placeholder={t("form.company")} onChange={event => setCompany(event.target.value)} required />
                        </div>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="vat" type="text" id="vat" placeholder={t("form.vatno")} onChange={event => setVat(event.target.value)} required />
                        </div>
                        <div className="mb-4">
                            <select className="border border-slate-700 placeholder-black rounded-md p-2 w-full" name="industry" id="industry" onChange={event => setIndustry(event.target.value)} required>
                                <option>{t("form.industry")}</option>
                                <option value={t("form.masons")}>{t("form.masons")}</option>
                                <option value={t("form.landscape gardener")}>{t("form.landscape gardener")}</option>
                                <option value={t("form.material lessor")}>{t("form.material lessor")}</option>
                                <option value={t("form.soil concrete")}>{t("form.soil concrete")}</option>
                                <option value={t("form.demolition")}>{t("form.demolition")}</option>
                                <option value={t("form.excavation")}>{t("form.excavation")}</option>
                                <option value={t("form.agriculture")}>{t("form.agriculture")}</option>
                                <option value={t("form.forestry")}>{t("form.forestry")}</option>
                                <option value={t("form.other")}>{t("form.other")}</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="name" type="text" id="name" placeholder={t("form.name")} onChange={event => setName(event.target.value)} required />
                        </div>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="email" type="text" id="email" placeholder={t("form.email")} onChange={event => setEmail(event.target.value)} required />
                        </div>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="phone" type="text" id="phone" placeholder={t("form.phone")} onChange={event => setPhone(event.target.value)} required />
                        </div>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="address" type="text" id="address" placeholder={t("form.address")} onChange={event => setAddress(event.target.value)} required />
                        </div>
                        <div className="mb-4">
                            <input className="border placeholder-black rounded-md p-2 w-full" name="address2" type="text" id="address2" placeholder={t("form.address2")} onChange={event => setAddress2(event.target.value)} />
                        </div>
                        <div className="columns-2 mb-4">
                            <div>
                                <input className="border placeholder-black rounded-md p-2 w-full" name="postno" type="text" id="postno" placeholder={t("form.postno")} onChange={event => setPostno(event.target.value)} required />
                            </div>
                            <div>
                                <input className="border placeholder-black rounded-md p-2 w-full" name="city" type="text" id="city" placeholder={t("form.city")} onChange={event => setCity(event.target.value)} required />
                            </div>
                        </div>
                        <div className="mb-4">
                            <select className="border placeholder-black rounded-md p-2 w-full" name="country" id="country" onChange={event => setCountry(event.target.value)} required>
                                <option>{t("form.select")}</option>
                                <option value="AL">{t("form.al")}</option>
                                <option value="AT">{t("form.at")}</option>
                                <option value="AU">{t("form.au")}</option>
                                <option value="BE">{t("form.be")}</option>
                                <option value="BG">{t("form.bg")}</option>
                                <option value="BR">{t("form.br")}</option>
                                <option value="CA">{t("form.ca")}</option>
                                <option value="CH">{t("form.ch")}</option>
                                <option value="CN">{t("form.cn")}</option>
                                <option value="CY">{t("form.cy")}</option>
                                <option value="CZ">{t("form.cz")}</option>
                                <option value="DE">{t("form.de")}</option>
                                <option value="DK">{t("form.da")}</option>
                                <option value="EE">{t("form.ee")}</option>
                                <option value="ES">{t("form.es")}</option>
                                <option value="FI">{t("form.fi")}</option>
                                <option value="FO">{t("form.fo")}</option>
                                <option value="FR">{t("form.fr")}</option>
                                <option value="GB">{t("form.gb")}</option>
                                <option value="GR">{t("form.gr")}</option>
                                <option value="HR">{t("form.hr")}</option>
                                <option value="HU">{t("form.hu")}</option>
                                <option value="IE">{t("form.ie")}</option>
                                <option value="IS">{t("form.is")}</option>
                                <option value="IT">{t("form.it")}</option>
                                <option value="LT">{t("form.lt")}</option>
                                <option value="LU">{t("form.lu")}</option>
                                <option value="LV">{t("form.lv")}</option>
                                <option value="MK">{t("form.mk")}</option>
                                <option value="MT">{t("form.mt")}</option>
                                <option value="MX">{t("form.mx")}</option>
                                <option value="NL">{t("form.nl")}</option>
                                <option value="PL">{t("form.pl")}</option>
                                <option value="PT">{t("form.pt")}</option>
                                <option value="RO">{t("form.ro")}</option>
                                <option value="ROK">{t("form.rok")}</option>
                                <option value="SE">{t("form.se")}</option>
                                <option value="SK">{t("form.sk")}</option>
                                <option value="SG">{t("form.sg")}</option>
                                <option value="SI">{t("form.si")}</option>
                                <option value="TH">{t("form.th")}</option>
                                <option value="TR">{t("form.tr")}</option>
                                <option value="US">{t("form.us")}</option>
                                <option value="ZA">{t("form.za")}</option>
                            </select>
                        </div>
                        {/*
                        <div className="mb-4">
                            <label className="font-bold"><input type="checkbox" name="accepted" className="w-6 h-6 mr-4 translate-y-1" required /> {t("form.accept")}</label>
                        </div>
                        */}
                        <div className="text-center">
                            <input className="py-3 px-12 text-white font-bold rounded-md cursor-pointer" style={{backgroundColor: "#F49600"}} type="submit" value={t("form.submit")} />
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form
import Language from "./Language"

const Header = () => {
    return (
        <header className="p-4 mb-8 bg-brand-sand">
            <div className="container mx-auto columns-2">
                <div>
                    <img src="/logo.png" alt="Baron CleverConveyor" />
                </div>
                <div className="float-right">
                    <Language />
                </div>
            </div>
        </header>
    )
}

export default Header;
import { useEffect } from 'react'
import axios from 'axios'
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function Verified() {
    const { t } = useTranslation()
    let { guid,email } = useParams()
   
    useEffect(() => {
        axios.post("https://prod-153.westeurope.logic.azure.com:443/workflows/7cf44b8447024386b9b919179c7c33c1/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=8gQ3A5CChKU7TucznCukA8cXYEruWGBQKkzpAJD0VKM",{
            "guid":guid,
            "email":email
        })
        .then(function(response) {
            console.log(response)
        })
    }, [email, guid])
    
    
    return (
        <div className="text-center my-32">
            <h1 className="text-4xl lg:text-5xl text-center font-bold my-24 px-8 lg:px-0 text-brand-orange">{t('verified.title')}.</h1>
            <p className="text-lg">{t('verified.part1')}{email}{t('verified.part2')}</p>
            <p><a href="https://baron-mixer.com">{t('verified.link')}</a></p>
        </div>
    )
}